import { useSettings } from '@common/core/settings/use-settings';
import { Link, NavLink } from 'react-router-dom';
import { useTrans } from '@common/i18n/use-trans';
import { useIsDarkMode } from '@common/ui/themes/use-is-dark-mode';
import { CustomMenu } from '@common/menus/custom-menu';
import { Trans } from '@common/i18n/trans';
import { IconButton } from '@common/ui/buttons/icon-button';
import { PlaylistAddIcon } from '@common/icons/material/PlaylistAdd';
import { ReactNode } from 'react';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import { CreatePlaylistDialog } from '@app/web-player/playlists/crupdate-dialog/create-playlist-dialog';
import { useAuthUserPlaylists } from '@app/web-player/playlists/requests/use-auth-user-playlists';
import { getPlaylistLink } from '@app/web-player/playlists/playlist-link';
import clsx from 'clsx';
import { useNavigate } from '@common/utils/hooks/use-navigate';
import { useAuthClickCapture } from '@app/web-player/use-auth-click-capture';
import { NavbarFeedbackButton } from '@common/forms/beta-feedback-navbar-form';
import { TopUpCredits } from '@common/modals/top-up-modal';
import { ChatBubbleLeftIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import { BetaBannerModal } from '@app/landing-page/beta-banner-modal';
import { useAuth } from '@common/auth/use-auth';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { cloneElement, Fragment, ReactElement, useContext, useState, useEffect } from 'react';
import { AccountCircleIcon } from '@common/icons/material/AccountCircle';
import { SiteConfigContext } from '@common/core/settings/site-config-context';
import { Button } from '@common/ui/buttons/button';
import { LikeIconButton } from '@app/web-player/library/like-icon-button';
import { DownloadIcon } from '@common/icons/material/Download';
import { Track } from '@app/web-player/tracks/track';
import { FavoriteBorderIcon } from '@common/icons/material/FavoriteBorder';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { STRIPE_PUBLIC_KEY } from '@app/common-conf';

import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { navigationLinks } from './navigation-links';
import beatsoraLogo from '@app/landing-page/images/intro/beatsora logo white compressed.png'
import { FavoriteIcon } from '@common/icons/material/Favorite';
import { FileDownloadIcon } from '@common/icons/material/FileDownload';
import { AccountBoxIcon } from '@common/icons/material/AccountBox';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY); // Replace with your Stripe publishable key


const menuItemClassName = (isActive: boolean): string => {
  return clsx(
    'h-44 px-12 mx-12 hover:bg-hover rounded-lg',
    isActive && 'text-danger'
  );
};

export function NewButton(){
  return(
    <div className='bg-danger text-black text-[10px] font-semibold py-1 px-3'>
      NEW
    </div>
  )
}


export function SidebarNavigation(){
  const { user } = useAuth();
  const { auth } = useContext(SiteConfigContext);
  return (
    <>
        <dl className="mt-24 space-y-6 px-10 divide-y divide-white/20">
          {navigationLinks.map((priLink) => (
            <Disclosure as="div" key={priLink.id} className="pt-6">
              {({ open }) => (
                <>
                  <dt>
                  {!priLink.list && 
                      <NavLink to={priLink.hrefSidebar}>
                        <Disclosure.Button className="flex w-full items-center justify-between text-left text-white hover:bg-white/10 rounded-md">
                          <div className="flex gap-x-8 px-10 py-4 items-center  overflow-hidden">
                            <priLink.icon className='text-white/60 w-20 h-20'/>
                            <span className="text-lg font-semibold leading-7 line-clamp-1">{priLink.sidebarName}</span>
                            {priLink.isNew && 
                            <NewButton/>
                            }
                          </div>
                          {priLink.list && <span className="pr-6 flex h-20 items-center  overflow-hidden">
                            {open ? (
                              <MinusSmallIcon className="h-18 w-18" aria-hidden="true" />
                            ) : (
                              <PlusSmallIcon className="h-18 w-18" aria-hidden="true" />
                            )}
                          </span>
                          }
                        </Disclosure.Button>
                      </NavLink>
                    }
                    {priLink.list && 
                      <Disclosure.Button className="flex w-full items-center justify-between text-left text-white hover:bg-white/10 rounded-md">
                        <div className="flex gap-x-8 px-10 py-4 items-center overflow-hidden">
                          <priLink.icon className='text-white/60 w-20 h-20'/>
                          <span className="text-lg font-semibold leading-7 line-clamp-1">{priLink.sidebarName}</span>
                          {priLink.isNew && 
                            <NewButton/>
                          }
                        </div>
                        {priLink.list && <span className="pr-6 flex h-20 items-center overflow-hidden">
                          {open ? (
                            <MinusSmallIcon className="h-18 w-18" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-18 w-18" aria-hidden="true" />
                          )}
                        </span>
                        }
                      </Disclosure.Button>
                    }
                  </dt>
                  {priLink.list && <Disclosure.Panel as="dd" className="mt-5 pr-4">
                  <dl className="mt-8 pb-8 space-y-4 overflow-hidden">

                    {priLink.content.map((secLink) => 
                      <>
                        {secLink.visibleInSidebar && (
                          <NavLink 
                          to={secLink.href}
                          target={secLink.newTab ? "_blank" : "_self"} 
                          rel={secLink.newTab ? "noopener noreferrer" : undefined}
                          >        
                            <div className="px-10 text-white hover:bg-white/10 rounded-md items-center">
                              <div className="px-10 py-4 flex gap-x-8 items-center">
                                <secLink.icon className='text-white/60 w-20 h-20'/>
                                <span className="text-md font-normal leading-7 line-clamp-1">{secLink.name}</span>
                                {secLink.isNew && 
                                  <NewButton/>
                                }
                              </div>
                            </div>
                          </NavLink>
                        )}
                      </>
                    )}
                  </dl>

                  </Disclosure.Panel>
                  }
                </>
              )}
            </Disclosure>
            
          ))}

        {auth.getUserProfileLink && user && (
          <Disclosure as="div" className="pt-6">
              {({ open }) => (
                <>
                  <dt>
                      <Disclosure.Button className="flex w-full items-center justify-between text-left text-white hover:bg-white/10 rounded-md">
                        <div className="flex gap-x-8 px-10 py-4 items-center overflow-hidden">
                          <AccountBoxIcon className='text-white/60 w-20 h-20'/>
                          <span className="text-lg font-semibold leading-7 line-clamp-1">Your Music</span>
                        </div>
                        <span className="pr-6 flex h-20 items-center overflow-hidden">
                          {open ? (
                            <MinusSmallIcon className="h-18 w-18" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-18 w-18" aria-hidden="true" />
                          )}
                        </span>
                        
                      </Disclosure.Button>
                    
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-5 pr-4">
                  <dl className="mt-8 pb-8 space-y-4  overflow-hidden">

                    <NavLink to={auth.getUserProfileLink(user).concat("/tracks")}>        
                      <div className="px-10 text-white  hover:bg-white/10 rounded-md items-center">
                        <div className="px-10 py-4 flex gap-x-8 items-center">
                          <FavoriteIcon className='text-white/60 w-20 h-20'/>
                          <span className="text-md font-normal leading-7 line-clamp-1">Likes</span>
                        </div>
                      </div>
                    </NavLink>
                    <NavLink to={auth.getUserProfileLink(user).concat("/downloads")}>        
                      <div className="px-10 text-white  hover:bg-white/10 rounded-md items-center">
                        <div className="px-10 py-4 flex gap-x-8 items-center">
                          <FileDownloadIcon className='text-white/60 w-20 h-20'/>
                          <span className="text-md font-normal leading-7 line-clamp-1">Downloads</span>
                        </div>
                      </div>
                    </NavLink>
                  </dl>

                  </Disclosure.Panel>
                  
                </>
              )}
            </Disclosure>

        )}


        </dl>
    </>
  )
}




interface Props {
  className?: string;
}
export function Sidenav({ className }: Props) {
  const { user } = useAuth();
  const { auth } = useContext(SiteConfigContext);

  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  return (
    <div className={clsx('border-r py-12 bg-alt overflow-y-auto', className)}>
      <div className='pt-24'/>
      <Logo />

      <div className='pt-16 pl-20 block lg:hidden'>
        <ActionButtons />
      </div>

      <div className='pt-4'/>
      <SidebarNavigation/>
      
      <div className="mt-24">
         
        <CustomMenu
          className="mt-12 text-sm items-stretch"
          menu="sidebar-secondary"
          orientation="vertical"
          gap="gap-none"
          iconClassName="text-muted"
          itemClassName={({ isActive }) => menuItemClassName(isActive)}
        />
        <PlaylistSection />

      </div>
    </div>
  );
}

interface SectionTitleProps {
  children?: ReactNode;
}
function SectionTitle({ children }: SectionTitleProps) {
  return (
    <div className="uppercase text-xs font-semibold text-muted mb-8 mx-24">
      {children}
    </div>
  );
}

function Logo() {
  const { trans } = useTrans();
  const isDarkMode = useIsDarkMode();

  const darkLogo = beatsoraLogo;
  const lightLogo = beatsoraLogo;

  const logoUrl = isDarkMode ? darkLogo : lightLogo;

  return (
    <Link
      to="/"
      className="block flex-shrink-0 mx-18"
      aria-label={trans({ message: 'Go to homepage' })}
    >
      <img
        className="block w-auto h-auto max-w-[188px] object-contain"
        src={logoUrl}
        alt={trans({ message: 'Site logo' })}
      />
    </Link>
  );
}


function PlaylistSection() {
  const { data } = useAuthUserPlaylists();
  const navigate = useNavigate();
  const authHandler = useAuthClickCapture();

  return (
    <div className="mt-40">
      <div className="flex items-center justify-between mr-24">
        <SectionTitle>
          <Trans message="Playlists" />
        </SectionTitle>
        <DialogTrigger
          type="modal"
          onClose={newPlaylist => {
            if (newPlaylist) {
              navigate(getPlaylistLink(newPlaylist));
            }
          }}
        >
          <IconButton
            className="flex-shrink-0 text-muted"
            onClickCapture={authHandler}
          >
            <PlaylistAddIcon />
          </IconButton>
          <CreatePlaylistDialog />
        </DialogTrigger>
      </div>
      {data?.playlists?.map(playlist => (
        <NavLink
          to={getPlaylistLink(playlist)}
          key={playlist.id}
          className={({ isActive }) =>
            clsx(menuItemClassName(isActive), 'flex items-center text-sm')
          }
        >
          <div className="overflow-hidden overflow-ellipsis">
            {playlist.name}
          </div>
        </NavLink>
      ))}
    </div>
  );
}

function ActionButtons() {
  const { player, billing } = useSettings();
  const { isLoggedIn, hasPermission, isSubscribed } = useAuth();

  const showUploadButton =
    player?.show_upload_btn && isLoggedIn && hasPermission('music.create');
  const showTryProButton =
    billing?.enable && hasPermission('plans.view') && !isSubscribed;

  return (
    <Fragment>
      {showTryProButton ? (
        <Button
          variant="outline"
          size="sm"
          color="danger"
          elementType={Link}
          to="/pricing"
        >
          <Trans message="Subscribe" />
        </Button>
      ) : null}
    </Fragment>
  );
}