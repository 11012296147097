import { AlbumIcon } from '@common/icons/material/Album';
import { AudiotrackIcon } from '@common/icons/material/Audiotrack';
import { ExploreIcon } from '@common/icons/material/Explore';
import { NewReleasesIcon } from '@common/icons/material/NewReleases';
import { HorizontalSplitIcon } from '@common/icons/material/HorizontalSplit';
import { BuildIcon } from '@common/icons/material/Build';
import { FeedbackIcon } from '@common/icons/material/Feedback';
import { AutoAwesomeIcon } from '@common/icons/material/AutoAwesome';
import { GroupsIcon } from '@common/icons/material/Groups';
import { AutorenewIcon } from '@common/icons/material/Autorenew';
import { ShoppingCartIcon } from '@common/icons/material/ShoppingCart';
import { MenuBookIcon } from '@common/icons/material/MenuBook';
import { SellIcon } from '@common/icons/material/Sell';
import { HubIcon } from '@common/icons/material/Hub';
import { SchoolIcon } from '@common/icons/material/School';
import { MicIcon } from '@common/icons/material/Mic';
import { TextSnippetIcon } from '@common/icons/material/TextSnippet';
import { OndemandVideoIcon } from '@common/icons/material/OndemandVideo';
import { CampaignIcon } from '@common/icons/material/Campaign';

export const navigationLinks = [
    {
      id: 1,
      sidebarName: "Explore",
      navbarName: "",
      visibleInNavbar: false,
      icon: ExploreIcon,
      list: false,
      hrefSidebar: "/explore",
      hrefNavbar: null,
      highlightLinks: [],
      isNew: false,
      isPrimary: false,
      content: [],
    },
    {
      id: 2,
      sidebarName: "Beats",
      navbarName: "Explore Beats",
      visibleInNavbar: true,
      icon: AudiotrackIcon,
      list: true,
      hrefSidebar: "",
      hrefNavbar: null,
      highlightLinks: ["/explore"],
      isNew: false,
      isPrimary: true,
      content: [
        {
          id: 1,
          name: "Explore",
          icon: ExploreIcon,
          href: "/explore",
          isNew: false,
          description: "Browse our beat store for your next banger!",
          visibleInSidebar: false,
          newTab: false,
        },
        {
          id: 2,
          name: "New Releases",
          icon: NewReleasesIcon,
          href: "/channel/new-releases",
          isNew: false,
          description: "Check out the newest beats we have on offer!",
          visibleInSidebar: true,
          newTab: false,
        },
        {
          id: 3,
          name: "Beat Packs",
          icon: AlbumIcon,
          href: "/channel/popular-albums",
          isNew: false,
          description: "Scope our latest beat packs!",
          visibleInSidebar: true,
          newTab: false,
        },
        {
          id: 4,
          name: "Collections",
          icon: HorizontalSplitIcon,
          href: "/channel/collections",
          isNew: false,
          description: "Explore our curated beat collections!",
          visibleInSidebar: true,
          newTab: false,
        },
        {
          id: 5,
          name: "Genres",
          icon: SellIcon,
          href: "/channel/genres",
          isNew: false,
          description: "Browse by your favorite genre!",
          visibleInSidebar: true,
          newTab: false,
        },
        {
          id: 6,
          name: "Exclusive Beats",
          icon: ShoppingCartIcon,
          href: "https://www.beatsora.world",
          isNew: false,
          description: "Want exclusive beats? Check out our exclusive store!",
          visibleInSidebar: true,
          newTab: false,
        },
      ],
    },
    {
      id: 3,
      sidebarName: "Artist Tools",
      navbarName: "Artist Tools",
      visibleInNavbar: true,
      icon: BuildIcon,
      list: true,
      hrefSidebar: "",
      hrefNavbar: null,
      highlightLinks: ["aimastering", "/feedback-form", "/vocal-presets", "/partners", "/ai-lyrics", "/promotion"], 
      isNew: true,
      isPrimary: false,
      content: [
        {
          id: 1,
          name: "Promotion",
          icon: CampaignIcon,
          href: "/promotion",
          isNew: true,
          description: "Use our promotion service to get your music heard by a wider audience!",
          visibleInSidebar: true,
          newTab: false,
        },
        {
          id: 2,
          name: "AI Mastering",
          icon: AutoAwesomeIcon,
          href: "/create-aimastering",
          isNew: false,
          description: "Want your beats to pop? Check our AI mastering tool to enhance your next track!",
          visibleInSidebar: true,
          newTab: false,
        },
        {
          id: 3,
          name: "AI Lyric Generator",
          icon: TextSnippetIcon,
          href: "/ai-lyrics",
          isNew: true,
          description: "Struggling to come up with lyrics? Use our AI lyric generator to find inspiration!",
          visibleInSidebar: true,
          newTab: false,
        },
        {
          id: 4,
          name: "Track Feedback",
          icon: FeedbackIcon,
          href: "/feedback-form",
          isNew: false,
          description: "Submit your tracks to get reviewed by our team of accomplished producers and musicians!",
          visibleInSidebar: true,
          newTab: false,
        },
        {
          id: 5,
          name: "Vocal Presets",
          icon: MicIcon,
          href: "/vocal-presets",
          isNew: true,
          description: "Do your vocals sound flat and boring? Download our vocal presets to sound professional!",
          visibleInSidebar: true,
          newTab: false,
        },
        {
          id: 6,
          name: "Partner Discounts",
          icon: GroupsIcon,
          href: "/partners",
          isNew: false,
          description: "Get exclusive discounts for other platforms!",
          visibleInSidebar: true,
          newTab: false,
        },
      ],
    },
    {
      id: 4,
      sidebarName: "Resources",
      navbarName: "Resources",
      visibleInNavbar: true,
      icon: MenuBookIcon,
      list: true,
      hrefSidebar: "",
      hrefNavbar: null,
      highlightLinks: ["/hub", "/education"],
      isNew: true,
      isPrimary: false,
      content: [
        {
          id: 1,
          name: "Artist Hub",
          icon: HubIcon,
          href: "/hub",
          isNew: false,
          description: "Read our articles to stay up-to-date with Beatsora News, and get free tutorials to improve your music!",
          visibleInSidebar: true,
          newTab: false,
        },
        {
          id: 2,
          name: "Education",
          icon: SchoolIcon,
          href: "/education",
          isNew: false,
          description: "Are you an organization looking for facilitators to teach music? Check out Beatsora Education!",
          visibleInSidebar: true,
          newTab: false,
        },
        {
          id: 3,
          name: "Tutorial",
          icon: OndemandVideoIcon,
          href: "https://www.youtube.com/watch?v=UNUR8KpHxkc",
          isNew: true,
          description: "Watch our video tutorial to learn how to use Beatsora!",
          visibleInSidebar: true,
          newTab: true,
        },
      ],
    },
    {
      id: 5,
      sidebarName: "Pricing",
      navbarName: "Pricing",
      visibleInNavbar: true,
      icon: AutorenewIcon,
      list: false,
      hrefSidebar: "/pricing",
      hrefNavbar: "/pricing",
      highlightLinks: ["/pricing"],
      isNew: false,
      isPrimary: false,
      content: [],
    },
  ]